import React, { useEffect } from 'react'

import { navigate } from 'gatsby'

import { NoAuthLayout } from '../components/LayoutContainer'
import { Spin } from '../components/Spin'

import {
  ACCESS_ERROR,
  checkAuth,
  request as api,
  setAuthToken
} from '../services/strapi/api'

import { useError } from '../hooks/useError'

const GooglePage = ({ location }) => {
  const { setError } = useError()

  useEffect(() => {
    async function getJwtToken () {
      try {
        const response = await api.get(
          `/auth/google/callback${location.search}`
        )

        const { data: { jwt: token, user } = {} } = response || {}
        if (token && user && !user.blocked) {
          checkAuth(token).then(async ({ status, data }) => {
            if (status === 200) {
              const { canAccessCavalryApp } = data.role.capabilities || {}

              if (canAccessCavalryApp) {
                setAuthToken(token)
              } else {
                setError(ACCESS_ERROR)
              }
              await navigate('/')
            }
          })
        } else {
          setError(
            <>
              Your account has been blocked by the administrator.{' '}
              <a
                href="mailto:support@x-team.com"
                target="_blank"
                rel="noreferrer"
              >
                Please contact support
              </a>
            </>
          )
          await navigate('/')
        }
      } catch (error) {
        console.error(error.request?.status)
        const message =
          typeof error?.request?.response === 'string'
            ? JSON.parse(error.request?.response)?.message
            : undefined
        setError(message)

        await navigate('/')
      }
    }

    getJwtToken()
  }, [location, location?.search, setError])

  return (
    <div className="spinnerContainer">
      <Spin tip="Loading..." size="large" />
    </div>
  )
}

const GooglePageLayout = ({ location }) => (
  <NoAuthLayout>
    <GooglePage location={location} />
  </NoAuthLayout>
)

export default GooglePageLayout
